import { createWebHistory, createRouter } from 'vue-router'
import DefaultLayout from '@/layout/DefaultLayout.vue'

import FrontPage from '@/views/FrontPage/FrontPage.vue'
import AboutPage from '@/views/AboutPage/AboutPage.vue'
import ErrorPage from '@/views/404/ErrorPage.vue'

const routes = [
	{
		path: '/',
		name: 'FrontPage',
		component: FrontPage,
		meta: { layout: DefaultLayout }
	},
	{
		path: '/about',
		name: 'AboutPage',
		component: AboutPage,
		meta: { layout: DefaultLayout }
	}
	// {
	// 	path: "/:pathMatch(.*)*",
	// 	name: "NotFound",
	// 	component: ErrorPage,
	// 	meta: {layout: DefaultLayout},
	// },
]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		// always scroll to top
		return { top: 0 }
	}
})

export default router

<script setup>
//Тег <IMG который в зависимости от условий, подставляет url ретина
import { onMounted, onUnmounted, nextTick, computed, ref, inject } from 'vue'
import { gsap, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
const props = defineProps({
	y: {
		type: Number,
		required: false,
		default: 0
	},
	x: {
		type: Number,
		required: false
	},
	scaleFrom: {
		type: Number,
		required: false,
		default: 1
	},
	scaleTo: {
		type: Number,
		required: false,
		default: 1
	}
})
const thisElement = ref()
const animBlock = ref()
let thisTL
function initAnimation() {
	thisTL = gsap.timeline({
		scrollTrigger: {
			trigger: thisElement.value,
			start: 'top bottom',
			end: 'bottom top-=' + props.y,
			scrub: true,
			invalidateOnRefresh: true
		}
	})
	thisTL.fromTo(
		animBlock.value,
		{ y: 0, scale: props.scaleFrom },
		{ y: () => props.y, scale: props.scaleTo, ease: Linear.easeNone }
	)
}
onMounted(() => {
	initAnimation()
})
onUnmounted(() => {
	thisTL.kill()
})
function bla() {
	thisTL.kill()
}
</script>

<template>
	<div class="skarallax" ref="thisElement">
		<div class="skarallax-animation" ref="animBlock">
			<slot></slot>
		</div>
	</div>
</template>

<script setup>
//Тег <video в прелоадере при загрузке страницы
import { onMounted, onUnmounted, computed, ref, inject } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const props = defineProps({
	src: {
		type: String,
		required: true,
		default: ''
	},
	width: {
		type: Number,
		required: false
	},
	height: {
		type: Number,
		required: false
	},
	loop: {
		type: Boolean,
		default: true,
		required: false
	}
})
const isLoaded = ref(false)
const videoElement = ref()
const interval = ref()
const ratio = computed(() => {
	let style = {}
	let ratio
	if (props.width && props.height) {
		ratio = (props.height / props.width) * 100
		style.paddingTop = ratio + '%'
	}
	return style
})
const urlToLoad = computed(() => {
	let url = false
	if (store.state.preloader.isReadyToLoad === true) {
		console.log('readytoload')
		url = new URL('/' + props.src, import.meta.url).href
		interval.value = setInterval(function () {
			console.log(videoElement.value.readyState)
			if (videoElement.value.readyState >= 4) {
				handleSuccessLoad()
			}
		}, 300)
	}
	return url
})
function addToPreloader() {
	store.commit('preloader/plus')
}

function handleSuccessLoad() {
	isLoaded.value = true
	store.commit('preloader/minus')
	videoElement.value.play()
	console.log(videoElement.value)
	console.log(`${urlToLoad.value} ${'%c'} is loaded`, 'background: #222; color: #bada55')
	clearInterval(interval.value)
}
function handleErrorLoad() {
	console.log(`${urlToLoad.value} ${'%c'}is NOT loaded`, 'background: #222;; color: red')
}
onMounted(() => {
	addToPreloader()
})
onUnmounted(() => {
	interval.value = null
})
</script>

<template>
	<div class="video-box" :style="ratio" :class="[{ withRatio: props.width }, { loaded: isLoaded }]">
		<div class="video">
			<video ref="videoElement" v-if="urlToLoad" muted :loop="props.loop" @error="handleErrorLoad">
				<source type="video/mp4" :src="urlToLoad" />
				<p>Sorry, your browser does not support the &lt;video&gt; element.</p>
			</video>
		</div>
	</div>
</template>
<style scoped lang="scss"></style>

<script setup>
import { inject, computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { switchLanguage } from '@/i18n'
import './Header.scss'
const emitter = inject('emitter')

const store = useStore()

const isShowedLangMenu = ref(false)
const currentLang = ref('ru')
const langList = ref(['ru', 'en'])
const otherLang = ref('en')

function changeLang() {
	const code = otherLang.value
	otherLang.value = currentLang.value
	currentLang.value = code
	switchLanguage(code)
}
</script>
<template>
	<header class="header">
		<div class="container">
			<div class="header-row">
				<div class="left-col">
					<a href="#" class="logo">
						<img src="/assets/images/vector/logo.svg" alt="" />
					</a>
				</div>
				<div class="right-col">
					<ul class="main-nav">
						<li>
							<router-link :to="{ name: 'FrontPage' }">
								<span>Home</span>
							</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'AboutPage' }">
								<span>About</span>
							</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'AboutPage' }">
								<span>Cases</span>
							</router-link>
						</li>
					</ul>
					<div class="soc-links">
						<a href="#"><img src="/assets/images/vector/soc-telegram.svg" alt="" /></a>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import ru from './locales/ru.json'

export const i18n = createI18n({
	locale: import.meta.env.VITE_LOCALE_DEFAULT,
	fallbackLocale: import.meta.env.VITE_LOCALE_FALLBACK,
	legacy: false,
  globalInjection: true,
	warnHtmlMessage: true,
	messages: { en, ru }
})

export async function switchLanguage(newLocale) {
	i18n.global.locale.value = newLocale
	localStorage.setItem('locale', newLocale)
	document.documentElement.setAttribute('lang', newLocale)
}
<script setup>
//Тег <IMG который в зависимости от условий, подставляет url ретина
import { onMounted, computed, ref, watch, inject } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const props = defineProps({
	path: {
		type: String,
		required: true,
		default: ''
	},
	startFrame: {
		type: Number,
		required: false,
		default: 0
	},
	endFrame: {
		type: Number,
		required: true,
		default: 0
	},
	class: {
		type: String,
		required: false,
		default: ''
	},
	extension: {
		type: String,
		required: false,
		default: 'webp'
	},
	onScroll: {
		type: Boolean,
		required: true,
		default: false
	},
	debug: {
		type: Boolean,
		required: false
	}
})
const isLoaded = ref(false)
const canvasElem = ref()
const imagesUrls = ref([])
let canvas
let context
const frames = {
	frame: 0
}
function prepareArrayToLoad(path) {
	for (let i = props.startFrame; i <= props.endFrame; i++) {
		const imagePath = '/' + props.path + '/' + i.toString().padStart(4, '0') + '.' + props.extension
		imagesUrls.value.push(new URL(imagePath, import.meta.url).href)
		store.commit('preloader/plus')
	}
}

const loadImage = (url) =>
	new Promise((resolve, reject) => {
		const img = new Image()
		img.addEventListener('load', () => resolve(img))
		img.addEventListener('error', (err) => reject(err))
		img.src = url
	})

const canvasImages = computed(() => {
	let imgArray = []

	if (store.state.preloader.isReadyToLoad === true) {
		imagesUrls.value.map((imgPath) => {
			loadImage(imgPath)
				.then((img) => {
					if (props.debug === true)
						console.log(`${imgPath} ${'%c'} is loaded`, 'background: #222; color: #bada55')
					imgArray.push(img)
					store.commit('preloader/minus')
				})
				.catch((err) => {
					console.log(`${imgPath} ${'%c'}is NOT loaded`, 'background: #222;; color: red')
				})
		})
	}

	return imgArray
})

// watch(
// 	() => loaded,
// 	(isLoaded) => {
// 		if (isLoaded === true) {
// 			console.log('loaded')
// 		}
// 	},
// 	{ immediate: false }
// )

function drawCanvasDefault(frameNumber) {
	context.clearRect(0, 0, canvas.width, canvas.height)
	context.drawImage(props.canvasPicturesDefault[frameNumber], 0, 0)
}

function handleLoadedImage() {
	isLoaded.value = true
}
function handleErrorLoadingImage() {
	console.error(`image ${imageElement.value.src} is not loaded`)
}
onMounted(() => {
	prepareArrayToLoad()
})
</script>

<template>
	<canvas
		class="sequence"
		v-if="canvasImages.length > 0"
		height="500"
		:class="props.class"
		ref="canvasElem"
	></canvas>
</template>

import {ref} from "vue"
//для детекта мобилы? который используется во многих компонентах
//есть реактивное поле, которое апдейтится при ресайзе браузера
export function isMobileChecker() {
	let isMobile = ref()
	let documentWidth
	function update(event) {
		documentWidth = document.documentElement.clientWidth
		isMobile.value = documentWidth < 768;
	}
	window.addEventListener('resize', update)
	update()
	return isMobile
}

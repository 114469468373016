import { createStore } from 'vuex'
import preloader from '@/store/Preloader'

const store = createStore({
  modules: {
    preloader,
  }
})

export default store

export default {
	namespaced: true,
	state: {
		allResourcesLoaded: false,
		isReadyToLoad: false,
		isLoadedPictures: false,
		allPicsToLoadAmount: 0,
		picsToLoad: 0,
		percentLoaded: 0
	},

	actions: {
		loadAllPictures({ commit }, status) {
			commit('makeLoadAllPictures', true)
		},
		actReadyToLoad({ commit }, value) {
			commit('readyToLoad', value)
		},
		resetPreloader({ commit }) {
			commit('flushLoader')
		}
	},

	mutations: {
		plus(state, url) {
			state.picsToLoad++
			state.allPicsToLoadAmount++
		},
		minus(state, url) {
			state.picsToLoad--
			state.allResourcesLoaded = state.picsToLoad <= 0
		},
		flushLoader(state, url) {
			state.allPicsToLoadAmount = 0
			state.picsToLoad = 0
			state.isReadyToLoad = false
		},
		makeLoadAllPictures(state, value) {
			state.isLoadedPictures = value
		},
		readyToLoad(state, value) {
			state.isReadyToLoad = value
		}
	},

	getters: {
		picsToLoad(state) {
			return state.picsToLoad
		},
		allPicsAmount(state) {
			return state.allPicsToLoadAmount
		},
		isAllLoaded(state) {
			return state.allResourcesLoaded
		},
		percentLoaded(state) {
			return parseInt((100 / state.allPicsToLoadAmount) * state.picsToLoad)
		}
	}
}

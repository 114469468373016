<script setup>
import ModuleFooter from '@/layout/Footer/PageFooter.vue'
import ModuleHeader from '@/layout/Header/PageHeader.vue'
import { inject, ref, watch, nextTick, onMounted, onUnmounted } from 'vue'
import { gsap } from 'gsap'
const smoother = ref()
const smoothWrapperEl = ref()
const smoothContentEl = ref()
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
gsap.registerPlugin(ScrollSmoother, ScrollTrigger)
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Preloader from '@/layout/Preloader/Preloader.vue'
const route = useRoute()
const store = useStore()
onMounted(() => {
	smoother.value = ScrollSmoother.create({
		wrapper: smoothWrapperEl.value,
		content: smoothContentEl.value,
		smooth: 1.3, // how long (in seconds) it takes to "catch up" to the native scroll position 		// how long (in seconds) it takes to "catch up" to the native scroll position
		effects: true, // looks for data-speed and data-lag attributes on elements
		smoothTouch: true,
		speed: 1.5
	})
	console.log(`mounted layout`)
})
watch(
	() => route.fullPath,
	(path) => {
		store.dispatch('preloader/resetPreloader')
	},
	{ immediate: false }
)
</script>

<template>
	<div class="page-holder" id="smooth-wrapper" ref="smoothWrapperEl">
		<div class="container-inside" id="smooth-content" ref="smoothContentEl">
			<module-header />
			<div class="all-page-holder">
				<slot />
			</div>
		</div>
	</div>
	<preloader />
	<module-footer />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import Preloader from '@/layout/Preloader/Preloader.vue'
import ModuleHeader from '@/layout/Header/PageHeader.vue'
import ModuleFooter from '@/layout/Footer/PageFooter.vue'

import { gsap } from 'gsap'
import { ScrollSmoother } from 'gsap/ScrollSmoother'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollSmoother, ScrollTrigger)

const smoother = ref()
const smoothWrapperEl = ref()
const smoothContentEl = ref()
const route = useRoute()
const layout = computed(() => {
	return route.meta.layout
})
onMounted(() => {
	console.log(`mounted app`)
	smoother.value = ScrollSmoother.create({
		wrapper: smoothWrapperEl.value,
		content: smoothContentEl.value,
		smooth: 1.3, // how long (in seconds) it takes to "catch up" to the native scroll position 		// how long (in seconds) it takes to "catch up" to the native scroll position
		effects: true, // looks for data-speed and data-lag attributes on elements
		smoothTouch: true,
		speed: 1.5
	})
	console.log(`mounted layout`)
})
</script>

<template>
	<div class="page-holder" id="smooth-wrapper" ref="smoothWrapperEl">
		<div class="container-inside" id="smooth-content" ref="smoothContentEl">
			<module-header />
			<div class="all-page-holder">
				<router-view />
			</div>
		</div>
	</div>
	<preloader />
	<module-footer />
</template>

<style></style>

<script setup>
import './Footer.scss'
import {computed, inject} from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const emitter = inject('emitter')

function handleTopUpBtn() {
	emitter.emit('openTopUpModal')
}

</script>

<template>
	<footer class="page-footer">
		<div class="footer-content">
			<div class="container-full">

      </div>
		</div>
	</footer>
</template>


